/**
 * @file css class names for font
 */

export function tailwindSurfaceFontClass(fontId: number): string {
  switch (fontId) {
    case 1:
      return 'font-sans'
    case 2:
      return 'font-serif'
    case 3:
      return 'font-cursive'
    case 4:
      return 'font-padlet-monospace'
    default:
      return 'font-sans'
  }
}
